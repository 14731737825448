.header_area button{
  background-color: transparent;
  border: none;
}
div.dropdown-inner{
  padding: 0!important;
}
div.dropdown-inner > .dropdown-toggle{
  padding: 9.5px 25px 9.5px 10px !important;
  border: none!important;
}
div.dropdown-inner > .dropdown-menu{
  border: none!important;
}
div.dropdown-inner > .dropdown-menu > .dropdown-item:first-child{
  border-top: 1px solid #ccc!important;
}
.header_area .quickmenu_area .btn_select+.dropdown-menu .dropdown-submenu-inner>.dropdown-submenu-item, .header_area .user_area .btn_select+.dropdown-menu .dropdown-submenu-inner>.dropdown-submenu-item{
  border-top: none;
}
.header_area .user_area span{
  padding-left: 4px;
}
.dropdown-toggle{
  cursor: pointer;
}
.dropdown-menu{
  border: 1px solid #ccc;
}
.sidebar_area .sidebar_list li span{
  font-size: 16px;
}
.header_area .quickmenu_area .btn_select+.dropdown-menu .dropdown-item, .header_area .user_area .btn_select+.dropdown-menu .dropdown-item{
  color: #999999;
  font-size: 14px;
}
.header_area .user_area .btn_select+.dropdown-menu .dropdown-item > i,.header_area .user_area .btn_select+.dropdown-menu .dropdown-item > span{
  pointer-events: none;
}
.table {
  font-weight: bold;
}
.th_style span{
  position: relative;
}
.th_style span > span.th_sort:before{
  content: '\e90c';
  top: -8px;
  left: 3px;
  position: absolute;
  opacity: .3;
}
.th_style span > span.th_sort:after{
  content: '\e90a';
  bottom: -8px;
  left: 3px;
  position: absolute;
  opacity: .3;
}
.th_style span > span.th_sort.sort_asc:before{
  opacity: 1;
  color: #fff;
}
.th_style span > span.th_sort.sort_desc:after{
  opacity: 1;
  color: #fff;
}