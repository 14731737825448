.header-area .btn-container {
	height: 50px;
}


.select-btn-block {
    min-width: 20%;
}


.text-button {
    display: inline;
	cursor: pointer;
}

.text-button:hover {
	opacity: 0.5;
}

.text-button:active {
	position: relative;
	top: 1px;
}

.work_area .table {
	table-layout: fixed;
	max-width: 1500px;
}

.table td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

table tbody tr:nth-child(n) td{
	background-color: #f2f2f2;
}
table tbody tr:nth-child(2n) td{
	background-color: white;
}
table :where(tbody,thead) :where(td,th).sticky_field_edit
{
	position: sticky;
}
table :where(tbody,thead) :where(td,th).sticky_field_delete
{
	position: sticky;
	right: 0;
}


select:active, select:focus {
	border: 1px solid #ced4da !important;
}
