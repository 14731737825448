button > i , button > span{
  pointer-events: none;
}
.form_select_block.w-8{
  width: 8%;
}
.form_select_block.w-8:after{
  right: 20px;
}
.subtitle_area{
  line-height: 46px;
  color: #6e7189;
}
.border_right{
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.form_select_block{
  margin-bottom: 0;
}
.form_select_block > .form_select_bind[disabled]{
  background: #e9ecef;
}
.col-form-label{
  align-self: center;
}
.react-bootstrap-daterangepicker-container{
  position: relative;
  width: 100%;
}
.react-bootstrap-daterangepicker-container.readOnly{
  pointer-events: none;
}
.react-bootstrap-daterangepicker-container.readOnly > input{
  background: -webkit-gradient(linear, left top, left bottom, from(#c3c2ce), color-stop(1%, #d4d2e0), color-stop(2%, #dfdce8), to(#dfdce8));
  background: -webkit-linear-gradient(top, #c3c2ce 0%, #d4d2e0 1%, #dfdce8 2%, #dfdce8 100%);
  background: -o-linear-gradient(top, #c3c2ce 0%, #d4d2e0 1%, #dfdce8 2%, #dfdce8 100%);
  background: linear-gradient(to bottom, #c3c2ce 0%, #d4d2e0 1%, #dfdce8 2%, #dfdce8 100%);
}
.modal_set.modal_event .checkbox_area .form-check-label:before{
  float: none;
}
.custom-file-input:lang(en)~.custom-file-label:after{
  content: '\e923';
}
.tabs_scroll::-webkit-scrollbar-track{
  border-width: 0px 1px;
  border-style: solid;
  border-color: #dee2e6;
}
.tabs_scroll::-webkit-scrollbar-thumb{
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #dee2e6;
}
.tabs_scroll::-webkit-scrollbar-track , .tabs_scroll::-webkit-scrollbar-thumb{
  background: #fff;
}
.pagination_area{
  margin-top: 20px;
}
.sort_area .button_group,.sort_area .button_group>span,.sort_area .button_group>span>label{
  height: 100%;
}
.dx-scheduler-fixed-appointments{
  z-index: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* 以下都是dashboard.js 需要的 css */
.carried_area .carried_info .oee_trends.flat {
  background-color: #0080FF;
}
.select-kanban .kanban-item-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.select-kanban .kanban-item-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 600ms;
}
.select-kanban .kanban-item-exit {
  opacity: 1;
  transform: translate3d(100%, 0, 0);
}
.select-kanban .kanban-item-exit-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 600ms;
}
.dashboard_area .dashboard_instant .boxes{
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 6px 0;
  min-width: 200px;
  text-align: center;
}
.dashboard_area .dashboard_instant .boxes.box_0{
  color: #727e8c;
}
.dashboard_area .dashboard_instant .boxes.box_1{
  border-color: #a1e9c1;
  background-color: #e9f9f0;
  color: #2dc76d;
}
.dashboard_area .dashboard_instant .boxes.box_2{
  background-color: #e40026;
  color: #fff;
}
.dashboard_area .dashboard_instant .boxes.box_3{
  background-color: #e0e1e4;
  color: #656b82;
}
.dashboard_area .dashboard_instant .boxes .large{
  font-size: 120%;
}
.dashboard_area .dashboard_instant .boxes.box_0 .large{
  color: #2dc76d;
}
.dashboard_area .dashboard_instant .boxes > .lh-3{
  line-height: 3;
}
.dashboard_area .dashboard_productivity .progress_bar{
  background-color: rgba(0, 0, 0, 0.05);
  height: 40px;
  border-radius: 5px;
  position: relative;
}
.dashboard_area .dashboard_productivity .progressing_bar{
  height: 40px;
  border-radius: 5px;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_red .progressing_bar{
  background-color: #e40026;
  width: 33%;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_red .progressing_text_right{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #6e7189;
  position: absolute;
  right: 10px;
  top: 12px;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_red .progressing_text_left{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #fff;
  position: absolute;
  left: 10px;
  top: 12px;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_green .progressing_bar{
  background-color: #00d85a;
  width: 40%;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_green .progressing_text_right{
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #00d85a;
  position: absolute;
  right: 10px;
  top: 12px;
}
.dashboard_area .dashboard_productivity .progress_bar.bar_green .progressing_text_left{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #fff;
  position: absolute;
  left: 10px;
  top: 12px;
}
.dashboard_area .dashboard_productivity .card + .card{
  margin-top: 1rem;
}
.dashboard_area .dashboard_productivity .card .title_sub_area{
  padding: 8px 0;
  margin: 0;
}
.dashboard_area .dashboard_productivity .card .title_sub_area.with_bgc{
  background-color: #e0e1e4;
}
.dashboard_area .dashboard_productivity .card .title_sub_area.with_bgc > span{
  color:#383b3f;
}
.dashboard_area .dashboard_productivity .card .title_sub_area > span{
  color: #727e8c;
  font-size: 18px;
  font-weight: 600;
}
.dashboard_area .dashboard_productivity .card .title_sub_area > span{
  color: #727e8c;
  font-size: 18px;
}
.dashboard_area .dashboard_productivity .card > ol {
  margin: 0.5rem 0 ;
}
.dashboard_area .dashboard_productivity .card.last_row{
  margin-top: 1rem;
}
.dashboard_area .dashboard_productivity .card.last_row > .row > .col-md-3 > div{
  margin: 0.3rem 1rem;
  color: #727e8c;
}
.dashboard_area .dashboard_productivity .card.last_row > .row > .col-md-3 +.col-md-3 {
  border-left: 1px solid #c7cfd6;
}
.dashboard_area .dashboard_productivity .card.last_row > .row > .col-md-3 > div span.small{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.dashboard_area .dashboard_productivity .card.last_row > .row > .col-md-3 > div b.large{
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.title_sub_area .pie_chart .pie_info span{
  font-size: inherit;
}
.dashboard_area .dashboard_productivity .title_sub_area div.progress-bar-v-container{
  position: relative;
  padding: 25px 1.2rem 25px 1.2rem;
  float: left;
}
.dashboard_area .dashboard_productivity .title_sub_area .progress-bar-vertical {
  width: 20px;
  min-height: 190px;
  display: flex;
  align-items: flex-end;
}
.dashboard_area .dashboard_productivity .title_sub_area .progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.dashboard_area .dashboard_productivity .title_sub_area div.progress-bar-v-container span.topspan{
  color: #727e8c;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.dashboard_area .dashboard_productivity .title_sub_area div.progress-bar-v-container span.btmspan{
  color: #727e8c;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.board-enter {
  transform: translate(100%);
  height: '100vh'
}
.board-enter.board-enter-active {
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
}
.board-exit {
  display: none;
}
.board-leave {
  transform: translate(0%);
  height: '100vh'
}
.board-leave.board-leave-active {
  transform: translate(-100%);
  transition: transform 1000ms ease-in-out;
}
/* 以下是analysis_amount的CSS */
.analysis_area .analysis_amount_inner{
  background-color: #fbfbfb;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow-y: auto;
  height: 626px;
  padding: 5px;
}
.button_group.three_btn>span{
  width: 33.33333333333%;
}
.analysis_area .interval_group{
  width: 25%;
}
.form_select_block .select_range{
  background: -webkit-gradient(linear, left top, left bottom, from(#fefeff), to(#efeff6));
  background: -webkit-linear-gradient(top, #fefeff 0%, #efeff6 100%);
  background: -o-linear-gradient(top, #fefeff 0%, #efeff6 100%);
  background: linear-gradient(to bottom, #fefeff 0%, #efeff6 100%);
  display: block;
  line-height: 18px;
}
.range_area .form_select_block:after{
  top: 18px;
}
/* .dx-scheduler 區間排版 */
.scheduler_area.days_interval .dx-scheduler-timeline .dx-scheduler-header-panel .dx-scheduler-header-row:last-child {  
  display: none;  
}
.management_schedule_area .scheduler_area.days_interval .dx-scheduler-header-panel tr th{
  width: 100px;
}

.w-42{
  width: 42%;
}

.permission_inner, .permission_title, .permission_sub {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #2e355b;
}

/* select disabled background color */
.form-control[disabled]{
    background-color: #eee;
    opacity: 1;
}
/* fullcalendar event prefix icon */
.fc-event-container .workday1::before{
	content: "❶ ";
	font-size: 1rem;
	padding-right: 3px;
}
.fc-event-container .workday2::before{
	content: "❷";
	font-size: 1rem;
	padding-right: 2px;
}
.fc-event-container .workday3::before{
	content: "❸";
	font-size: 1rem;
	padding-right: 2px;
}
.fc-event-container .workday4::before{
	content: "❹";
	font-size: 1rem;
	padding-right: 2px;
}
.fc-event-container .overtime::before{
	content: "●";
	font-size: 1.4rem;
	padding-right: 2px;
}
.fc-event-container .maintain::before{
	content: "🛠";
	font-size: 0.8rem;
	padding-right: 2px;
}
.select-kanban {
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  margin: 0;
}
.modal_set .checkbox_area .form-check-label:before {
  float: initial;
}

.carried_area .progress_area .second_scale{
  margin-top: -45px;
}
.carried_area .progress_area .second_scale>li .line{
  height: 50px;
  width: 0px;
  border-style: solid;
  border-color: #929292;
  border-width: 0 4px 0 0;
  background-color: transparent;
}
.carried_area .progress_area .progress .progress_red.cycle-bar{
  position: relative;
}
.carried_area .progress_area .progress .progress_red.cycle-bar:after{
  content: attr(aria-valuenow);
    color: #e40026;
    right: -40px;
}
.carried_inner .progress .line {
  height: 0px;
  position: absolute;
  border-style: dotted;
  border-color: #FF644E;
  border-width: 4px 0 0 0;
  background-color: transparent;
  text-align: right;
  width: calc(31.5vw - 90px);
  right: -2.5vw;
  font-family: "黑體";
}
@media only screen and (max-width: 992px) {
  .carried_inner .progress .line {
    width: calc(42.5vw - 90px);
  }
}
.carried_inner .line b {
  position: absolute;
  top: -30px;
  right: 0px;
  color: #000;
  font-size: 20px;
}

.warnText {
  color: #FF0000;
}

.scrollButton {
  color: #CCC;
  font-size: 36px;
  background-color: transparent;
  font-weight: bold;
}

.scrollButton:hover {
  color: #000;
}