button[modaltoshow] *{
  pointer-events: none;
}

/* toolManagement.css */
.vender {
  -webkit-text-stroke: 0.3px #75498d;
  font-family: MicrosoftSansSerif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 1.2px;
  text-align: center;
  color: #75498d;
  border: solid 1px #c8c5d5;
  background-image: linear-gradient(to bottom, #fefeff, #efeff6);
  padding: 10px 15px;
  cursor: pointer;
}
.clicked {
  background-color: #dfdce7;
  background-image: unset;
}

/* bomManagement.css */
.inputBlock {
  border-bottom: solid 2px #808080;
  width: 45%;
}
.inputBlock input {
  background-color: transparent;
  border: none;
}
.inputBlock input:focus {
  background-color: transparent;
}
.inputBlock i {
  color: #808080;
  font-size: 25px;
}
.productSelect {
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  color: #808080;
  background-color: white;
  border: solid 1px #c8c5d5;
  width: 100%;
}
.opList {
  width: 100%;
  display: none;
}
.productItem {
  padding: 5px 10px;
  color: #808080;
  background-color: white;
  border: solid 1px #c8c5d5;
  width: 100%;
  cursor: pointer;
}
.productItem:hover,
.opList .active {
  background-color: #c8c5d5;
}
.bomList {
  background-color: #efefef;
  overflow-y: auto;
  padding-top: 20px;
}
.greyArea {
  padding: 0 15px 15px 15px;
  background-color: #eeeeee;
  color: #808080;
  height: 10vh;
  font-weight: bold;
}
.greyArea .column>span {
  margin-bottom: 5px;
}
#machining i {
  padding: 0 5px;
  margin-right: 5px;
}
#machining i:hover {
  background-color: #bdbdbd;
}